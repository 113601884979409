
import {
	computed,
	defineComponent,
	onMounted,
	ref,
	watch,
	watchEffect,
} from 'vue'
import {
	SubDistrictI,
	DistrictI,
	ProvinceI,
	AddressI,
} from '@/api/address/address.api'
import { getUniqueListBy } from '@/utils/common'
import { useAddressStore } from '@/pinia/address/address.pinia'

type InputDefault = string | number | null | any
export interface AddressProp {
	address: string | null
	province: InputDefault
	district: InputDefault
	subDistrict: InputDefault
	zipCode: InputDefault
}

export default defineComponent({
	name: 'AddressSystem',
	props: {
		address: {
			type: String,
			default: null,
		},
		province: {
			type: Object,
			default: null,
		},
		district: {
			type: Object,
			default: null,
		},
		subDistrict: {
			type: Object,
			default: null,
		},
		zipCode: {
			type: Number,
			default: null,
		},
		isOrderForm: {
			type: Boolean,
			default: false,
		},
		isDisabledAllForm: {
			type: Boolean,
			default: false,
		},
		isSearchMultiple: {
			type: Boolean,
			default: false,
		},
		removeTargetTag: {
			type: Object,
			default: null,
		},
	},
	emits: [
		'onValidate',
		'update:address',
		'update:province',
		'update:district',
		'update:subDistrict',
		'update:zipCode',
		'remove-tag',
		'clear-tag',
	],
	setup(props, { emit }) {
		//
		const formSystemRef = ref<any>(null)
		const addressInputRef = ref()
		const searchAddress = ref(null)
		const oldSearchAddress = ref<string[]>([])
		const options = ref<AddressI[]>([])
		const isLoadingProvince = ref<boolean>(true)
		const addressStore = useAddressStore()
		const provinceList = computed<ProvinceI[]>(() => addressStore.province)
		// Computed from ProvinceList
		const districtList = computed<DistrictI[]>(() => {
			if (
				props.province &&
				props.province.provinceCode &&
				props.province.provinceName
			) {
				const filterByProvinceId = addressStore.provinceList.filter(
					(pv) => pv.provinceCode === props.province?.provinceCode
				)
				return getUniqueListBy(
					filterByProvinceId,
					'districtCode'
				) as DistrictI[]
			}
			return []
		})
		const subDistrictList = computed<SubDistrictI[]>(() => {
			if (props.district && districtList.value) {
				const filterByDistrictId = addressStore.provinceList.filter(
					(sub) => sub.districtCode === props.district?.districtCode
				)
				return getUniqueListBy(
					filterByDistrictId,
					'subDistrictCode'
				) as any
			}
			return []
		})
		const zipCodeList = computed(() => {
			if (subDistrictList?.value?.length && props.subDistrict) {
				const findZipCodeListFromSubDistrictCode =
					addressStore.provinceList.find((addr) => {
						return (
							addr.provinceCode === props.province?.provinceCode &&
							addr.districtCode === props.district?.districtCode &&
							addr.subDistrictCode === props.subDistrict?.subDistrictCode
						)
					})
				return findZipCodeListFromSubDistrictCode?.zipCode || []
			}
			return []
		})

		const onValidate = (
			validateKey: string,
			isValid: boolean,
			message: string
		) => {
			emit('onValidate', { validateKey, isValid, message })
		}

		const validateZipCode = (_: any , value: any, callback: any) => {
			let isValid = false

			if (!props.subDistrict?.subDistrictCode) {
				isValid = true
			}

			if (!value && !isValid) {
				return callback(new Error('กรุณาระบุรหัสไปรษณีย์'))
			}
			callback()
			return
		}

		const validateAll = async () => {
			const isValid = await formSystemRef.value?.validate()
			return isValid
		}

		const validateDescription = (_: any, value: string, callback: any) => {
			const removeAllSpace = value?.replaceAll(' ', '')
			if (!removeAllSpace) {
				return callback(new Error('กรุณากรอกรายละเอียดที่อยู่'))
			}
			callback()
			return
		}

		const handleSearchAddress = (queryString: string) => {
			if (!queryString) return []

			const findAddressByZipCode = addressStore.provinceList.filter(
				(addr) => addr.zipCode.includes(Number(queryString))
			)
			if (!findAddressByZipCode?.length) return []

			const extractDupplicateZipCode = []

			for (const addr of findAddressByZipCode) {
				if (addr.zipCode.length > 1) {
					for (const zipCode of addr.zipCode) {
						extractDupplicateZipCode.push({
							...addr,
							zipCode: [zipCode],
						})
					}
				} else {
					extractDupplicateZipCode.push(addr)
				}
			}
			
			options.value = extractDupplicateZipCode
		}

		const convertSearchAddressToJSON = () => {
			const convertToString = JSON.stringify(searchAddress.value)
			const convertToJSON = JSON.parse(convertToString)
			return convertToJSON
		}

		const handleRemoveTag = (data: string) => {
			const convertToJSON = convertSearchAddressToJSON()
			const filterRemoveTag = convertToJSON.filter(
				(item: any) => JSON.stringify(item) !== data
			)
			searchAddress.value = filterRemoveTag
			emit('remove-tag', JSON.parse(data))
			return true
		}
		
		const handleClearTag = () => {
			emit('clear-tag')
		}

		const updateOldSearchAddress = (data: string[]) => {
			let result = false

			oldSearchAddress.value.push(...data)

			const filterDuplicate = oldSearchAddress.value.filter((item, index) => {
				return oldSearchAddress.value.indexOf(item) === index
			})

			const filterData = filterDuplicate.filter((item) => {
				return !data.includes(item)
			})

			filterData.forEach((item) => {
				result = handleRemoveTag(item)
			})

			if (result) {
				oldSearchAddress.value = [...data]
				return true
			}
		}

		const handleSelectAddress = (data: string[]) => {
			if (Array.isArray(data) && data.length === 0) {
				emit('clear-tag')
				return
			}

			const result = updateOldSearchAddress(data)
			if (result) return

			for (const item of data) {
				const addressItem: AddressI = JSON.parse(item)

				// emit('update:address', `ตำบล ${addressItem.districts.subDistricts.name} อำเภอ ${addressItem.districts.name} จังหวัด ${addressItem.name} ${addressItem.districts.subDistricts.zipCode}`) // Update Address
				// Emit Province, District, SubDistrict, ZipCode
				emit('update:province', { provinceName: addressItem.provinceName, provinceCode: addressItem.provinceCode })
				emit('update:district', { districtName: addressItem.districtName, districtCode: addressItem.districtCode })
				emit('update:subDistrict', { subDistrictName: addressItem.subDistrictName, subDistrictCode: addressItem.subDistrictCode })
				emit('update:zipCode', addressItem.zipCode?.[0] as any)

				// Focus address
				// addressInputRef.value.focus()
				// const element = document.getElementById('addressInput')
				// element?.scrollIntoView({ behavior: 'smooth' })
			}
		}

		const onProvinceChange = (value: number) => {
			emit('update:province', value)
			// clear district, subDis, zipCode
			emit('update:district', null)
			emit('update:subDistrict', null)
			emit('update:zipCode', null)
		}

		const onDistrictChange = (value: number) => {
			emit('update:district', value)
			// clear, subDis, zipCode
			emit('update:subDistrict', null)
			emit('update:zipCode', null)
		}

		const onSubDistrictChange = (value: number) => {
			emit('update:subDistrict', value)
			// Clear Zipcode
			// emit('update:zipCode', zipCodeList.value[0])
		}

		watch(() => searchAddress.value, () => {
			if (!searchAddress.value) {
				oldSearchAddress.value = []
			}
		})

		watch(() => props.removeTargetTag, (data) => {
			if (
				data &&
				Object.keys(data).length > 0 &&
				data.province?.provinceCode &&
				data.district?.districtCode &&
				data.subDistrict?.subDistrictCode &&
				data.zipCode
			) {
				const convertToJSON = convertSearchAddressToJSON()
				const filter = convertToJSON.filter((item: any) => {
					const { provinceCode, districtCode, subDistrictCode, zipCode } = JSON.parse(item)
					
					if (
						provinceCode === data.province?.provinceCode &&
						districtCode === data.district?.districtCode &&
						subDistrictCode === data.subDistrict?.subDistrictCode &&
						zipCode[0] === data.zipCode
					) {
						return false
					}

					return true
				})
				searchAddress.value = filter
			} else {
				searchAddress.value = null
			}
		})

		watchEffect(() => {
			if (props.subDistrict) {
				if (props.zipCode) {
					const filterZipcode = zipCodeList.value?.find(
						(zipCode) => zipCode === props.zipCode
					)
					emit('update:zipCode', filterZipcode)
				} else {
					emit('update:zipCode', (zipCodeList.value as any)?.[0] || null)
				}
			}
		})

		onMounted(() => {
			isLoadingProvince.value = true
			addressStore.getAllProvinceStore().finally(() => {
				isLoadingProvince.value = false
			})
		})

		return {
			onValidate,
			formSystemRef,
			searchAddress,
			validateAll,
			handleSearchAddress,
			handleSelectAddress,
			handleRemoveTag,
			handleClearTag,
			provinceList,
			districtList,
			subDistrictList,
			zipCodeList,
			onProvinceChange,
			onDistrictChange,
			onSubDistrictChange,
			isLoadingProvince,
			addressInputRef,
			validateDescription,
			options,
			validateZipCode
		}
	},
})
