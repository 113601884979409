import { AxiosResponse } from 'axios'
import axios from '../instance'
import { InsertReconcilePayload, IReconcileTransfer, ReconcileParams, InsertCodHold, CodHoldDetail, NotReceiveParams, LogReconcilePreviewPayload, PreviewReconcileCourier } from './reconcile.type'


export const getReconcileLogPreview = async (payload: LogReconcilePreviewPayload): Promise<any> => {
	try {
		const response = await axios.post('/admin/checking-reconcile-order', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}


export const insertReconcile = async (payload: InsertReconcilePayload): Promise<any> => {
	try {
		const response = await axios.post('/admin/reconcile-courier', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const clearReconcile = async (courierId: string): Promise<any> => {
	try {
		const response = await axios.post('/admin/clear-reconcile-courier', { courierId })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getReconcileNotReceive = async (payload: NotReceiveParams): Promise<any> => {
	try {
		const response = await axios.get('/admin/reconcile-not-receive', { params: { ...payload }})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const runBatchLogReconcile = async (logReconcileId: string): Promise<any> => {
	try {
		const response = await axios.put('/admin/update-log-reconcile-status', { logReconcileId })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getReconcileList = async ({ offset = 1, limit = 10 }): Promise<any> => {
	try {
		const response = await axios.get('/admin/reconcile', {
			params: {
				offset: offset,
				limit: limit,
			}
		})
		return response

	} catch (error) {
		return Promise.reject(error)
	}
}

export const confirmReconcileNewOrder = async (logReconcileId: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/admin/reconcile-order', {
			logReconcileId
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}


export const getSummaryCourier = async (logReconcileId: string) => {
	const { data } = await axios.post('/admin/summary-reconcile-order', { logReconcileId })
	return data
}

export const getCodHoldList = async (params: InsertCodHold): Promise<any> => {
	try {
	  const response = await axios.get('/admin/cod-hold-list', {
			params: {
		  		startDate: params.startDate,
		  		endDate: params.endDate,
			},
	  })
	  return response
	} catch (error) {
	  return Promise.reject(error)
	}
}
  
export const getCodHoldShopDetail = async (params :CodHoldDetail): Promise<any> => {
	try {
		const response = await axios.get('/admin/cod-hold-list-detail', {
			params: {
		  		startDate: params.startDate,
		  		endDate: params.endDate,
				shopId: params.shopId,
			}
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getReconcileAllShop = async ({ offset = 1, limit = 10, reconcileId = '' }): Promise<any> => {
	try {
		const response = await axios.get(`/admin/reconcile/${reconcileId}`, {
			params: {
				offset: offset,
				limit: limit,
			}
		})
		return response
		
	} catch (error) {
		return Promise.reject(error)
	}
}

export const reconcileShopOrder = async (params: ReconcileParams): Promise<any> => {
	try {
		const response = await axios.get(`/admin/reconcile/shop/${params.reconcileId}`, {
			params: {
				shopId: params.shopId,
				offset: params.offset,
				limit: params.limit,
			}
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const reconcileTransferUpdate = async (payload: IReconcileTransfer): Promise<any> => {
	try {
		const response = await axios.put('/admin/reconcile/transfer', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const generateReconcilePdf = async (reconcileId: string, shopId: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/pdf-generator/remittance', {
			reconcileId,
			shopId,
		}, { responseType: 'blob' })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const reconcileTransaction = async ({ reconcileId = '', isScb = true, shops = [] }): Promise<AxiosResponse> => {
	try {
		const response = await axios.post(`/admin/reconcile-transaction/${reconcileId}`, {
			isScb,
			shops,
		},{
			responseType: 'blob'
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}



export const confirmReconcile = async ({ reconcileId = '', shops = [] }): Promise<AxiosResponse> => {
	try {
		const response = await axios.post(`/admin/confirm-reconcile-transaction/${reconcileId}`, {
			shops
		}, {
			responseType: 'blob'
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getReconcilePreview = async (formData: FormData, params: PreviewReconcileCourier): Promise<any> => {
	try {
		const response = await axios.post('/admin/diff-order-cod', formData, {
			params: {
		  		startDate: params.startDate,
		  		endDate: params.endDate,
				courier: params.courier,
			}
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}