import { ref, onUnmounted, computed, ComputedRef, onMounted } from 'vue'
interface WindowSize {
  width: number;
  height: number;
}
export interface UseWindowSizeI {
	windowSize(): WindowSize;
	mobileMode: ComputedRef<boolean>;
}
export const useWindowSize = (): UseWindowSizeI => {

	const windowSize = (): WindowSize => {
		const windowSize = ref<WindowSize>({
			width: window.innerWidth,
			height: window.innerHeight,
		})

		const onResize = () => {
			windowSize.value = {
				width: window.innerWidth,
				height: window.innerHeight,
			}
		}

		onMounted(() => {
			window.addEventListener('resize', onResize, false)
		})
		onUnmounted(() => {
			window.removeEventListener('resize', onResize, false)
		})
		return windowSize.value
	}

	const mobileMode = computed((): boolean => {
		const size = windowSize()
		return size.width <= 768
	})

	return {
		windowSize,
		mobileMode
	}
}