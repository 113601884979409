import { computed, onMounted,ref ,reactive,watch} from 'vue'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { DATE_SELECT } from '@/constants/dashborad'
import dayjs from 'dayjs'
import { getDashboardBackOffice } from '@/api/dashboard/dashboard.api'
import xlsx from 'xlsx'
import { autofitColumns } from '@/utils/xlsx'
import { mapThaiMonth } from '@/constants/date'
export enum EXPORT_TYPE {
	ERROR_WEIGHT = 'ERROR_WEIGHT',
	ERROR_MERCHANT = 'ERROR_MERCHANT',
	ERROR_REJECT = 'ERROR_REJECT',
	DIFF_MERCHANT_AND_ORDER = 'DIFF_MERCHANT_AND_ORDER'
}
export enum GRAP_TYPE {
	ACTIVE_ORDER = 'activeOrder',
	ORDER_CHARGE = 'orderCharge',
	PROFIT_INFO = 'profitInfo',
	ACTIVE_SHOP = 'activeShop',
	PROFIT_BY_ORDER = 'profitByOrder',
	DIFF_MERCHANT_FEE = 'diffMerchantFee',
	DIFF_ORDER_TOTAL = 'diffOrderTotal',
	BAD_DEBT_AMOUNT_CREDIT = 'badDebtAmountCredit',
	UNPAID_BILL_PAYMENT = 'unpaidBillpayment',
}

enum ERROR_CREDIT_TYPE {
	EXCESS_CREDIT = 'excessCredit',
	LACK_CREDIT = 'lacklackCredit',
}

export const useDashboardData = () => {

	const shopStore = useShopStore()
	const isLoadingUser = ref<boolean>(false)
	const isLoadingOrder = ref<boolean>(false)
	const currentTab = ref('overall')
	const activeGrap = ref('activeOrder')
	const dashboardData = ref()

	const dashboardBackOffice = ref()
	const startDate = computed(() => state.datePick[0])
	const endDate = computed(() => state.datePick[1])

	const isFromBtn = ref(true)
	const state = reactive({
		isLoading: false,
		datePick: computed(() => shopStore.datePick),
		reconcile: {
			codTotal: 0,
			reconcileDate: null as null | string,
		},
		isGenerateReport: false,
	})
	const selectData = ref()
	const textHeader = ref<string>('ข้อมูลย้อนหลัง 30 วัน')

	const fetchDashboard = async () => {
		isLoadingOrder.value = true
		state.isLoading = true
		try {
			const params={
				startDate: startDate.value,
				endDate: endDate.value
			}
			const response = await getDashboardBackOffice(params)
			dashboardBackOffice.value = response
		}catch(error) {
			console.log('error:', error)
		}finally {
			isLoadingUser.value = false
			isLoadingOrder.value = false
		}
		state.isLoading = false
	}
	const onSetDatePickup = async () => {
		shopStore.updateDateSelect(DATE_SELECT.DATE_PICK)
		selectDate(shopStore.dateSelect)
	}

	const getErrorInfo = (type : string, dasboardDate : string) => {
		let title = ''
		let listErrors = []
		let fileName = ''
		if(type == EXPORT_TYPE.ERROR_WEIGHT){
			title = 'พัสดุที่ไม่ได้กรอกน้ำหนัก'
			listErrors = dashboardBackOffice.value?.orderWeight.errors
			fileName = `error-order-${dasboardDate}.xlsx`
		} else if(type == EXPORT_TYPE.ERROR_MERCHANT){
			title = 'พัสดุที่ส่งสำเร็จแต่ยังไม่ชำระเงิน'
			listErrors = dashboardBackOffice.value?.orderCompleted.errors
			fileName = `merchant-order-${dasboardDate}.xlsx`
		} else if(type == EXPORT_TYPE.ERROR_REJECT) {
			title = 'พัสดุตีกลับที่คิดเงิน'
			listErrors = dashboardBackOffice.value?.orderRejectCharge.errors
			fileName = `reject-order-${dasboardDate}.xlsx`
		} else if(type == EXPORT_TYPE.DIFF_MERCHANT_AND_ORDER) {
			title = 'รายละเอียดเครดิตที่เกิดปัญหา'
			listErrors = dashboardBackOffice.value?.creditMerchantFee.errors
			fileName = `credit-merchant-order-${dasboardDate}.xlsx`
		}

		return {
			title,
			listErrors,
			fileName,
		}
	}

	const exportFailOrderReport = (type: string) => {
		//
		state.isGenerateReport = true

		const wsData = []
		const header = [
			'เลขพัสดุ',
			'ขนส่ง',
		]
		const startDateData = dayjs(startDate.value).format('DD/MM/YYYY')
		const endDateData = dayjs(endDate.value).format('DD/MM/YYYY')
		const dasboardDate = startDateData + (startDateData === endDateData ? '' : ' ~ ' + endDateData)

		const errorInfo = getErrorInfo(type,dasboardDate)
		wsData.push([
			errorInfo.title,
			dasboardDate,
		])
		wsData.push(header)
		for (const row of errorInfo.listErrors as any) {
			wsData.push([
				row.trackingNumber,
				row.courier,
			])
		}
		wsData.push([]) // Empty Row

		const worksheet = xlsx.utils.aoa_to_sheet(wsData)
		const workbook = xlsx.utils.book_new()

		// width maximize column with
		worksheet['!cols'] = autofitColumns(wsData)
		/* Add the worksheet to the workbook */
		xlsx.utils.book_append_sheet(workbook, worksheet, 'SheetJs')

		xlsx.writeFile(workbook, errorInfo.fileName)

		state.isGenerateReport = false
	}

	const exportFailRejectOrderReport = (type: string) => {
		//
		state.isGenerateReport = true

		const wsData = []
		const header = [
			'เลขพัสดุ',
		]
		const startDateData = dayjs(startDate.value).format('DD/MM/YYYY')
		const endDateData = dayjs(endDate.value).format('DD/MM/YYYY')
		const dasboardDate = startDateData + (startDateData === endDateData ? '' : ' ~ ' + endDateData)

		const errorInfo = getErrorInfo(type,dasboardDate)

		wsData.push([
			errorInfo.title,
			dasboardDate,
		])
		wsData.push(header)
		for (const row of errorInfo.listErrors as any) {
			wsData.push([
				row._id
			])
		}
		wsData.push([]) // Empty Row

		const worksheet = xlsx.utils.aoa_to_sheet(wsData)
		const workbook = xlsx.utils.book_new()

		// width maximize column with
		worksheet['!cols'] = autofitColumns(wsData)
		/* Add the worksheet to the workbook */
		xlsx.utils.book_append_sheet(workbook, worksheet, 'SheetJs')

		xlsx.writeFile(workbook, errorInfo.fileName)

		state.isGenerateReport = false
	}

	const exportErrorCreditReport = (type: string) => {
		//
		state.isGenerateReport = true

		const wsData = []
		const header = [
			'',
			'ประเภท',
			'จำนวน/บาท',
		]
		const startDateData = dayjs(startDate.value).format('DD/MM/YYYY')
		const endDateData = dayjs(endDate.value).format('DD/MM/YYYY')
		const dasboardDate = startDateData + (startDateData === endDateData ? '' : ' ~ ' + endDateData)

		wsData.push([
			'รายละเอียดร้านค้าที่คิดเครดิตผิด',
			dasboardDate,
		])
		let listError = dashboardBackOffice.value?.diffErrorShopCredit.lackCredit
		let errorType = 'Lack-Credit'
		if(type === ERROR_CREDIT_TYPE.EXCESS_CREDIT){
			errorType = 'Excess-Credit'
			listError = dashboardBackOffice.value?.diffErrorShopCredit.excessCredit
		}
		for (const row of listError) {
			wsData.push([]) // Empty Row
			wsData.push(['รหัสร้าน : ' + row.shopId])
			wsData.push(header)
			
			for(const error of row.creditError){
				for(const credit of error.transaction){
					wsData.push([
						credit.transactionId,
						credit.typeCredit,
						credit.amount,
					])
					
				}
				wsData.push([]) // Empty Row
				wsData.push([
					'',
					'ผลรวมเครดิต',
					error.expectCurrentCredit,
					'บาท'
				])
				wsData.push([
					'',
					'ค่าเครดิตที่ควรจะเป็น',
					error.resultCurrentCredit,
					'บาท'
				])
				wsData.push([]) // Empty Row
			}
		}
		wsData.push([]) // Empty Row

		const worksheet = xlsx.utils.aoa_to_sheet(wsData)
		const workbook = xlsx.utils.book_new()

		// width maximize column with
		worksheet['!cols'] = autofitColumns(wsData)
		/* Add the worksheet to the workbook */
		xlsx.utils.book_append_sheet(workbook, worksheet, 'SheetJs')

		const fileName = `error-${errorType}-${dasboardDate}.xlsx`

		xlsx.writeFile(workbook, fileName)

		state.isGenerateReport = false
	}

	const exportErrorCreditMerchantFee = (type: string) => {
		//
		state.isGenerateReport = true

		const wsData = []
		const header = [
			'รหัสออเดอร์',
			'เครดิต/บาท',
			'รหัสเครดิต',
			'ประเภท',
			'หมายเหตุ',
		]
		const startDateData = dayjs(startDate.value).format('DD/MM/YYYY')
		const endDateData = dayjs(endDate.value).format('DD/MM/YYYY')
		const dasboardDate = startDateData + (startDateData === endDateData ? '' : ' ~ ' + endDateData)

		const errorInfo = getErrorInfo(type,dasboardDate)

		wsData.push([
			errorInfo.title,
			dasboardDate,
		])

		wsData.push(header)
		for (const row of errorInfo.listErrors) {
			// wsData.push([]) // Empty Row
			wsData.push([
				row.orderInfo.orderId || '-',
				row.orderInfo.merchantReturnFeeCredit || row.orderInfo.adjustMerchantCredit || row.orderInfo.holdingMerchantCredit || 0,
				row.creditId || '-',
				row.typeCredit || '-',
				row.message || '-',
			])
		}
		wsData.push([]) // Empty Row

		const worksheet = xlsx.utils.aoa_to_sheet(wsData)
		const workbook = xlsx.utils.book_new()

		// width maximize column with
		worksheet['!cols'] = autofitColumns(wsData)
		/* Add the worksheet to the workbook */
		xlsx.utils.book_append_sheet(workbook, worksheet, 'SheetJs')

		xlsx.writeFile(workbook, errorInfo.fileName)

		state.isGenerateReport = false
	}

	const selectDate = async (date:string) => {
		// isFromBtn.value = true
		shopStore.updateDateSelect(date)
		const yesterday = dayjs().subtract(1, 'd')
		if(date === DATE_SELECT.TODAY){
			textHeader.value = 'ข้อมูลเมื่อวาน'
			shopStore.updateDatePick([yesterday.startOf('day').format('YYYY-MM-DD'), yesterday.endOf('day').format('YYYY-MM-DD')])
		}
		else if(date === DATE_SELECT.SEVEN_DAYS){
			textHeader.value = 'ข้อมูลย้อนหลัง 7 วัน'
			shopStore.updateDatePick([yesterday.startOf('day').subtract(7, 'day').format('YYYY-MM-DD'), yesterday.endOf('day').format('YYYY-MM-DD')])
		}
		else if(date === DATE_SELECT.THIRTY_DAYS){
			textHeader.value = 'ข้อมูลย้อนหลัง 30 วัน'
			shopStore.updateDatePick([yesterday.startOf('day').subtract(30, 'day').format('YYYY-MM-DD'), yesterday.endOf('day').format('YYYY-MM-DD')])
		}
		else if(date === DATE_SELECT.MONTH_AGO){
			textHeader.value = 'ข้อมูลเดือนที่แล้ว'
			shopStore.updateDatePick([yesterday.subtract(1,'month').startOf('month').format('YYYY-MM-DD'), yesterday.subtract(1,'month').endOf('month').format('YYYY-MM-DD')])
		}
		else if(date === DATE_SELECT.DATE_PICK){
			const startDateNewFormat = dayjs(startDate.value).format('DD-MM-YYYY')
			const endDateNewFormat = dayjs(endDate.value).format('DD-MM-YYYY')
			shopStore.updateDatePick(shopStore.datePick)

			if(startDateNewFormat === endDateNewFormat){
				textHeader.value = `ข้อมูลวันที่ ${startDateNewFormat}`
			}else{
				textHeader.value = `ข้อมูลวันที่ ${startDateNewFormat} ถึง ${endDateNewFormat}`
			}
		}
		fetchDashboard()
	}
	const selectGrap = (type: string) => {
		activeGrap.value = type
		fetchCostlist(selectData.value ,startDate.value,endDate.value,type)
	}

	const costOrder = ref()
	const fetchCostlist = (
		data: any,
		startDate: string,
		endDate: string,
		activeGrap: string
	) => {
		if (activeGrap === GRAP_TYPE.ACTIVE_ORDER) {
			orderPickUpGrap(data, startDate, endDate)
		} else if(activeGrap === GRAP_TYPE.ORDER_CHARGE) {
			orderChargeGrap(data, startDate, endDate)
		} else if(activeGrap === GRAP_TYPE.PROFIT_INFO) {
			profitInfoGrap(data, startDate, endDate)
		} else if(activeGrap === GRAP_TYPE.ACTIVE_SHOP) {
			activeShopGrap(data, startDate, endDate)
		} else if(activeGrap === GRAP_TYPE.PROFIT_BY_ORDER) {
			profitByOrderGrap(data, startDate, endDate)
		} else if(activeGrap === GRAP_TYPE.DIFF_MERCHANT_FEE) {
			diffMerchantFeeGrap(data, startDate, endDate)
		} else if(activeGrap === GRAP_TYPE.DIFF_ORDER_TOTAL) {
			diffOrderTotalGrap(data, startDate, endDate)
		} else if(activeGrap === GRAP_TYPE.BAD_DEBT_AMOUNT_CREDIT) {
			deficitCreditGrap(data, startDate, endDate)
		} else if(activeGrap === GRAP_TYPE.UNPAID_BILL_PAYMENT) {
			unpaidCheckingGrap(data, startDate, endDate)
		}
	}

	const orderPickUpGrap = (data: any,startDate: string,endDate: string) => {
		const daysDiff = dayjs(endDate).diff(dayjs(startDate), 'day')
		// ออเดอร์
		const orderPickUpLabels = []
		const pickupTimeCounts = []
		const pickupTimeFlashs = []
		const pickupTimeFlashBulkys = []
		const pickupTimeThailandPosts = []
		const pickupTimeKerrys = []
		const pickupTimeSPXs = []
		const pickupTimeDhls = []

		if (daysDiff > 0) {
			for (let i = daysDiff; i >= 0; i--) {
				const dateLabel = dayjs(endDate).subtract(i, 'day')
				const calendarLabel = `${dateLabel.format('DD')} ${
					mapThaiMonth[dateLabel.format('MM')].short
				} ${dateLabel.format('YY')}`

				orderPickUpLabels.push(calendarLabel)

				const findMatchDate: any = data?.grapInfo?.data?.activeOrder?.find(
					(each: { dashboardAnalyticsDate: string }) => dayjs(each.dashboardAnalyticsDate).format('YYYY-MM-DD') === dateLabel.format('YYYY-MM-DD')
				  )
				let pickupTimeCount = 0
				let pickupTimeFlash = 0
				let pickupTimeFlashBulky = 0
				let pickupTimeThailandPost = 0
				let pickupTimeKerry = 0
				let pickupTimeSPX = 0
				let pickupTimeDhl = 0
				if (findMatchDate) {
					pickupTimeCount += findMatchDate.data.pickupTimeCount
					pickupTimeFlash += findMatchDate.data.pickupTimeFlash
					pickupTimeFlashBulky += findMatchDate.data.pickupTimeFlashBulky
					pickupTimeThailandPost += findMatchDate.data.pickupTimeThailandPost
					pickupTimeKerry += findMatchDate.data.pickupTimeKerry
					pickupTimeSPX += findMatchDate.data.pickupTimeSPX
					pickupTimeDhl += findMatchDate.data.pickupTimeDhl
					pickupTimeCounts.push(pickupTimeCount)
					pickupTimeFlashs.push(pickupTimeFlash)
					pickupTimeFlashBulkys.push(pickupTimeFlashBulky)
					pickupTimeThailandPosts.push(pickupTimeThailandPost)
					pickupTimeKerrys.push(pickupTimeKerry)
					pickupTimeSPXs.push(pickupTimeSPX)
					pickupTimeDhls.push(pickupTimeDhl)
				} else {
					pickupTimeCounts.push(0)
					pickupTimeFlashs.push(0)
					pickupTimeFlashBulkys.push(0)
					pickupTimeThailandPosts.push(0)
					pickupTimeKerrys.push(0)
					pickupTimeSPXs.push(0)
					pickupTimeDhls.push(0)
				}
			}
		} 
		else {
			for (let i = 0; i < 24; i++) {
				let pickupTimeCount = 0
				let pickupTimeFlash = 0
				let pickupTimeFlashBulky = 0
				let pickupTimeThailandPost = 0
				let pickupTimeKerry = 0
				let pickupTimeSPX = 0
				let pickupTimeDhl = 0
				if (data?.grapInfo?.data?.activeOrder?.length > 0) {
					for (const order of data?.grapInfo?.data?.activeOrder) {
						const orderHour = dayjs(order.createdAt).hour()
						if (orderHour === i) {
							pickupTimeCount += order.data.pickupTimeCount
							pickupTimeFlash += order.data.pickupTimeFlash
							pickupTimeFlashBulky += order.data.pickupTimeFlashBulky
							pickupTimeThailandPost += order.data.pickupTimeThailandPost
							pickupTimeKerry += order.data.pickupTimeKerry
							pickupTimeSPX += order.data.pickupTimeSPX
							pickupTimeDhl += order.data.pickupTimeDhl
						}
					}
				}
				pickupTimeCounts.push(pickupTimeCount)
				pickupTimeFlashs.push(pickupTimeFlash)
				pickupTimeFlashBulkys.push(pickupTimeFlashBulky)
				pickupTimeThailandPosts.push(pickupTimeThailandPost)
				pickupTimeKerrys.push(pickupTimeKerry)
				pickupTimeSPXs.push(pickupTimeSPX)
				pickupTimeDhls.push(pickupTimeDhl)
				orderPickUpLabels.push(`${i}:00`)
			}
		}

		const _data = {
			labels: orderPickUpLabels,
			datasets: [
				{
					label: 'ทั้งหมด',
					data: pickupTimeCounts,
					fill: false,
					borderColor: '#1B3CEB',
					backgroundColor: '#1B3CEB',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
				{
					label: 'Flash',
					data: pickupTimeFlashs,
					fill: false,
					borderColor: '#FCDC2A',
					backgroundColor: '#FCDC2A',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
				{
					label: 'Flash Bulky',
					data: pickupTimeFlashBulkys,
					fill: false,
					borderColor: '#000000',
					backgroundColor: '#000000',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
				{
					label: 'ThailandPost',
					data: pickupTimeThailandPosts,
					fill: false,
					borderColor: '#E72929',
					backgroundColor: '#E72929',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
				{
					label: 'Kerry',
					data: pickupTimeKerrys,
					fill: false,
					borderColor: '#e6a23c',
					backgroundColor: '#e6a23c',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
				{
					label: 'SPX',
					data: pickupTimeSPXs,
					fill: false,
					borderColor: '#67c23a',
					backgroundColor: '#67c23a',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
				{
					label: 'DHL',
					data: pickupTimeDhls,
					fill: false,
					borderColor: '#C68FE6',
					backgroundColor: '#C68FE6',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
			]
		}

		const lineChart = {
			id: 'costlineChart',
			type: 'line',
			data: _data,
			options: {
				animation: {
					duration: 200,
				},
				plugins: {
					legend: {
						display: true,
						position: 'bottom',
						align: 'end',
						labels: {
							usePointStyle: true,
							pointStyle: 'circle',
							boxWidth: 5,
							boxHeight: 5,
							fullSize: true,
						},
					},
				},
				scales: {
					x: {},
					y: {
						beginAtZero: true,
					},
				},
			},
		}

		costOrder.value = lineChart
	}
	const orderChargeGrap = (data: any,startDate: string,endDate: string) => {
		const daysDiff = dayjs(endDate).diff(dayjs(startDate), 'day')
		// ออเดอร์
		const orderChargeLabels = []
		const codOrderTotals = []
		const orderTotals = []
		const remoteZoneOrderTotals = []
		const transferOrderTotals = []
		const returnOrderTotals = []

		if (daysDiff > 0) {
			for (let i = daysDiff; i >= 0; i--) {
				const dateLabel = dayjs(endDate).subtract(i, 'day')
				const calendarLabel = `${dateLabel.format('DD')} ${
					mapThaiMonth[dateLabel.format('MM')].short
				} ${dateLabel.format('YY')}`

				orderChargeLabels.push(calendarLabel)

				const findMatchDate: any = data?.grapInfo?.data?.orderCharge?.find(
					(each: {dashboardAnalyticsDate: string }) => dayjs(each.dashboardAnalyticsDate).format('YYYY-MM-DD') === dateLabel.format('YYYY-MM-DD')
				)
				let codOrderTotal = 0
				let orderTotal = 0
				let remoteZoneOrderTotal = 0
				let transferOrderTotal = 0
				let returnOrderTotal = 0
				if (findMatchDate) {
					codOrderTotal += findMatchDate.data.codOrderTotal
					orderTotal += findMatchDate.data.orderTotal
					remoteZoneOrderTotal += findMatchDate.data.remoteZoneOrderTotal
					transferOrderTotal += findMatchDate.data.transferOrderTotal
					returnOrderTotal += findMatchDate.data.returnOrderTotal

					codOrderTotals.push(codOrderTotal)
					transferOrderTotals.push(transferOrderTotal)
					orderTotals.push(orderTotal)
					remoteZoneOrderTotals.push(remoteZoneOrderTotal)
					returnOrderTotals.push(returnOrderTotal)

				} else {
					codOrderTotals.push(0)
					transferOrderTotals.push(0)
					orderTotals.push(0)
					remoteZoneOrderTotals.push(0)
					returnOrderTotals.push(0)
				}
			}
		} 
		else {
			for (let i = 0; i < 24; i++) {
				let codOrderTotal = 0
				let transferOrderTotal = 0
				let orderTotal = 0
				let remoteZoneOrderTotal = 0
				let returnOrderTotal = 0
				if (data?.grapInfo?.data?.orderCharge?.length > 0) {
					for (const order of data?.grapInfo?.data?.orderCharge) {
						const orderHour = dayjs(order.createdAt).hour()
						if (orderHour === i) {
							codOrderTotal += order.data.codOrderTotal
							transferOrderTotal += order.data.transferOrderTotal
							orderTotal += order.data.orderTotal
							remoteZoneOrderTotal += order.data.remoteZoneOrderTotal
							returnOrderTotal += order.data.returnOrderTotal
						}
					}
				}

				codOrderTotals.push(codOrderTotal)
				transferOrderTotals.push(transferOrderTotal)
				orderTotals.push(orderTotal)
				remoteZoneOrderTotals.push(remoteZoneOrderTotal)
				returnOrderTotals.push(returnOrderTotal)
				orderChargeLabels.push(`${i}:00`)
			}
		}

		const _data = {
			labels: orderChargeLabels,
			datasets: [
				{
					label: 'ออเดอร์ทั้งหมด',
					data: orderTotals,
					fill: false,
					borderColor: '#1B3CEB',
					backgroundColor: '#1B3CEB',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
				{
					label: 'COD',
					data: codOrderTotals,
					fill: false,
					borderColor: '#FCDC2A',
					backgroundColor: '#FCDC2A',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
				{
					label: 'Transfer',
					data: transferOrderTotals,
					fill: false,
					borderColor: '#67c23a',
					backgroundColor: '#67c23a',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
				{
					label: 'RemoteZone',
					data: remoteZoneOrderTotals,
					fill: false,
					borderColor: '#E72929',
					backgroundColor: '#E72929',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
				{
					label: 'ออเดอร์ตีกลับ',
					data: returnOrderTotals,
					fill: false,
					borderColor: '#e6a23c',
					backgroundColor: '#e6a23c',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
			]
		}

		const lineChart = {
			id: 'costlineChart',
			type: 'line',
			data: _data,
			options: {
				animation: {
					duration: 200,
				},
				plugins: {
					legend: {
						display: true,
						position: 'bottom',
						align: 'end',
						labels: {
							usePointStyle: true,
							pointStyle: 'circle',
							boxWidth: 5,
							boxHeight: 5,
							fullSize: true,
						},
					},
				},
				scales: {
					x: {},
					y: {
						beginAtZero: true,
					},
				},
			},
		}

		costOrder.value = lineChart
	}
	const profitInfoGrap = (data: any,startDate: string,endDate: string) => {
		const daysDiff = dayjs(endDate).diff(dayjs(startDate), 'day')
		// ออเดอร์
		const profitInfoLabels = []
		const courierSums = []
		const merchantSums = []
		const profitSums = []

		if (daysDiff > 0) {
			for (let i = daysDiff; i >= 0; i--) {
				const dateLabel = dayjs(endDate).subtract(i, 'day')
				const calendarLabel = `${dateLabel.format('DD')} ${
					mapThaiMonth[dateLabel.format('MM')].short
				} ${dateLabel.format('YY')}`

				profitInfoLabels.push(calendarLabel)

				const findCourierMatchDate: any = data?.grapInfo?.data?.courierInfo?.find(
					(each: {dashboardAnalyticsDate: string }) => dayjs(each.dashboardAnalyticsDate).format('YYYY-MM-DD') === dateLabel.format('YYYY-MM-DD')
				)
				let courierSum = 0
				if (findCourierMatchDate) {
					courierSum += findCourierMatchDate.data.sum
					courierSums.push(courierSum)
				} else {
					courierSums.push(0)
				}

				const findMerchantMatchDate: any = data?.grapInfo?.data?.merchantInfo?.find(
					(each: {dashboardAnalyticsDate: string }) => dayjs(each.dashboardAnalyticsDate).format('YYYY-MM-DD') === dateLabel.format('YYYY-MM-DD')
				)
				let merchantSum = 0
				if (findMerchantMatchDate) {
					merchantSum += findMerchantMatchDate.data.sum
					merchantSums.push(merchantSum)
				} else {
					merchantSums.push(0)
				}

				const findProfitMatchDate: any = data?.grapInfo?.data?.profitInfo?.find(
					(each: {dashboardAnalyticsDate: string }) => dayjs(each.dashboardAnalyticsDate).format('YYYY-MM-DD') === dateLabel.format('YYYY-MM-DD')
				)
				let profitSum = 0
				if (findProfitMatchDate) {
					profitSum += findProfitMatchDate.data.profitSum
					profitSums.push(profitSum)
				} else {
					profitSums.push(0)
				}
			}
		} 
		else {
			for (let i = 0; i < 24; i++) {
				let courierSum = 0
				let merchantSum = 0
				let profitSum = 0
				if (data?.grapInfo?.data?.courierInfo?.length > 0) {
					for (const order of data?.grapInfo?.data?.courierInfo) {
						const orderHour = dayjs(order.createdAt).hour()
						if (orderHour === i) {
							courierSum += order.data.sum
						}
					}
				}
				if (data?.grapInfo?.data?.merchantInfo?.length > 0) {
					for (const order of data?.grapInfo?.data?.merchantInfo) {
						const orderHour = dayjs(order.createdAt).hour()
						if (orderHour === i) {
							merchantSum += order.data.sum
						}
					}
				}
				if (data?.grapInfo?.data?.profitInfo?.length > 0) {
					for (const order of data?.grapInfo?.data?.profitInfo) {
						const orderHour = dayjs(order.createdAt).hour()
						if (orderHour === i) {
							profitSum += order.data.profitSum
						}
					}
				}

				courierSums.push(courierSum)
				merchantSums.push(merchantSum)
				profitSums.push(profitSum)
				profitInfoLabels.push(`${i}:00`)
			}
		}

		const _data = {
			labels: profitInfoLabels,
			datasets: [
				{
					label: 'ต้นทุนค่าส่ง',
					data: courierSums,
					fill: false,
					borderColor: '#1B3CEB',
					backgroundColor: '#1B3CEB',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
				{
					label: 'ยอดขาย',
					data: merchantSums,
					fill: false,
					borderColor: '#FCDC2A',
					backgroundColor: '#FCDC2A',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
				{
					label: 'กำไร',
					data: profitSums,
					fill: false,
					borderColor: '#67c23a',
					backgroundColor: '#67c23a',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
			]
		}

		const lineChart = {
			id: 'costlineChart',
			type: 'line',
			data: _data,
			options: {
				animation: {
					duration: 200,
				},
				plugins: {
					legend: {
						display: true,
						position: 'bottom',
						align: 'end',
						labels: {
							usePointStyle: true,
							pointStyle: 'circle',
							boxWidth: 5,
							boxHeight: 5,
							fullSize: true,
						},
					},
				},
				scales: {
					x: {},
					y: {
						beginAtZero: true,
					},
				},
			},
		}

		costOrder.value = lineChart
	}
	const activeShopGrap = (data: any,startDate: string,endDate: string) => {
		const daysDiff = dayjs(endDate).diff(dayjs(startDate), 'day')
		// ออเดอร์
		const activeShopLabels = []
		const activeShops = []

		if (daysDiff > 0) {
			for (let i = daysDiff; i >= 0; i--) {
				const dateLabel = dayjs(endDate).subtract(i, 'day')
				const calendarLabel = `${dateLabel.format('DD')} ${
					mapThaiMonth[dateLabel.format('MM')].short
				} ${dateLabel.format('YY')}`

				activeShopLabels.push(calendarLabel)

				const findMatchDate: any = data?.grapInfo?.data?.shopActive?.find(
					(each: {dashboardAnalyticsDate: string }) => dayjs(each.dashboardAnalyticsDate).format('YYYY-MM-DD') === dateLabel.format('YYYY-MM-DD')
				)
				let activeShop = 0
				if (findMatchDate) {
					activeShop += findMatchDate.data
					activeShops.push(activeShop)
				} else {
					activeShops.push(0)
				}
			}
		} 
		else {
			for (let i = 0; i < 24; i++) {
				let activeShop = 0
				if (data?.grapInfo?.data?.shopActive?.length > 0) {
					for (const order of data?.grapInfo?.data?.shopActive) {
						const orderHour = dayjs(order.createdAt).hour()
						if (orderHour === i) {
							activeShop += order.data
						}
					}
				}

				activeShops.push(activeShop)
				activeShopLabels.push(`${i}:00`)
			}
		}

		const _data = {
			labels: activeShopLabels,
			datasets: [
				{
					label: 'ร้านค้าที่ใช้งาน',
					data: activeShops,
					fill: false,
					borderColor: '#1B3CEB',
					backgroundColor: '#1B3CEB',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
			]
		}

		const lineChart = {
			id: 'costlineChart',
			type: 'line',
			data: _data,
			options: {
				animation: {
					duration: 200,
				},
				plugins: {
					legend: {
						display: true,
						position: 'bottom',
						align: 'end',
						labels: {
							usePointStyle: true,
							pointStyle: 'circle',
							boxWidth: 5,
							boxHeight: 5,
							fullSize: true,
						},
					},
				},
				scales: {
					x: {},
					y: {
						beginAtZero: true,
					},
				},
			},
		}

		costOrder.value = lineChart
	}
	const profitByOrderGrap = (data: any,startDate: string,endDate: string) => {
		const daysDiff = dayjs(endDate).diff(dayjs(startDate), 'day')
		// ออเดอร์
		const profitByOrderLabels = []
		const profits = []

		if (daysDiff > 0) {
			for (let i = daysDiff; i >= 0; i--) {
				const dateLabel = dayjs(endDate).subtract(i, 'day')
				const calendarLabel = `${dateLabel.format('DD')} ${
					mapThaiMonth[dateLabel.format('MM')].short
				} ${dateLabel.format('YY')}`

				profitByOrderLabels.push(calendarLabel)

				const findMatchDate: any = data?.grapInfo?.data?.profitByOrder?.find(
					(each: {dashboardAnalyticsDate: string }) => dayjs(each.dashboardAnalyticsDate).format('YYYY-MM-DD') === dateLabel.format('YYYY-MM-DD')
				)
				let profit = 0
				if (findMatchDate) {
					profit += findMatchDate.data
					profits.push(profit)
				} else {
					profits.push(0)
				}
			}
		} 
		else {
			for (let i = 0; i < 24; i++) {
				let profit = 0
				if (data?.grapInfo?.data?.profitByOrder?.length > 0) {
					for (const order of data?.grapInfo?.data?.profitByOrder) {
						const orderHour = dayjs(order.createdAt).hour()
						if (orderHour === i) {
							profit += order.data
						}
					}
				}

				profits.push(profit)
				profitByOrderLabels.push(`${i}:00`)
			}
		}

		const _data = {
			labels: profitByOrderLabels,
			datasets: [
				{
					label: 'กำไร/ออเดอร์',
					data: profits,
					fill: false,
					borderColor: '#1B3CEB',
					backgroundColor: '#1B3CEB',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
			]
		}

		const lineChart = {
			id: 'costlineChart',
			type: 'line',
			data: _data,
			options: {
				animation: {
					duration: 200,
				},
				plugins: {
					legend: {
						display: true,
						position: 'bottom',
						align: 'end',
						labels: {
							usePointStyle: true,
							pointStyle: 'circle',
							boxWidth: 5,
							boxHeight: 5,
							fullSize: true,
						},
					},
				},
				scales: {
					x: {},
					y: {
						beginAtZero: true,
					},
				},
			},
		}

		costOrder.value = lineChart
	}
	const diffMerchantFeeGrap = (data: any,startDate: string,endDate: string) => {
		const daysDiff = dayjs(endDate).diff(dayjs(startDate), 'day')
		// ออเดอร์
		const diffMerchantAndOrderLabels = []
		const diffMerchantFees = []
		const defaultGrapZero = []

		if (daysDiff > 0) {
			for (let i = daysDiff; i >= 0; i--) {
				const dateLabel = dayjs(endDate).subtract(i, 'day')
				const calendarLabel = `${dateLabel.format('DD')} ${
					mapThaiMonth[dateLabel.format('MM')].short
				} ${dateLabel.format('YY')}`

				diffMerchantAndOrderLabels.push(calendarLabel)

				const findMerchantFeeMatchDate: any = data?.grapInfo?.data?.diffMerchantFee?.find(
					(each: {dashboardAnalyticsDate: string }) => dayjs(each.dashboardAnalyticsDate).format('YYYY-MM-DD') === dateLabel.format('YYYY-MM-DD')
				)
				let diffMerchantFee = 0
				if (findMerchantFeeMatchDate) {
					diffMerchantFee += findMerchantFeeMatchDate.data
					diffMerchantFees.push(diffMerchantFee)
				} else {
					diffMerchantFees.push(0)
				}
				defaultGrapZero.push(0)
			}
		} 
		else {
			for (let i = 0; i < 24; i++) {
				let diffMerchantFee = 0
				if (data?.grapInfo?.data?.diffMerchantFee?.length > 0) {
					for (const order of data?.grapInfo?.data?.diffMerchantFee) {
						const orderHour = dayjs(order.createdAt).hour()
						if (orderHour === i) {
							diffMerchantFee += order.data
						}
					}
				}
				diffMerchantFees.push(diffMerchantFee)
				defaultGrapZero.push(0)
				diffMerchantAndOrderLabels.push(`${i}:00`)
			}
		}

		const _data = {
			labels: diffMerchantAndOrderLabels,
			datasets: [
				{
					label: 'ส่วนต่างเครดิต',
					data: diffMerchantFees,
					fill: false,
					borderColor: '#1B3CEB',
					backgroundColor: '#1B3CEB',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
				{
					label: 'ค่าเริ่มต้น',
					data: defaultGrapZero,
					fill: false,
					borderColor: '#FCDC2A',
					backgroundColor: '#FCDC2A',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
			]
		}

		const lineChart = {
			id: 'costlineChart',
			type: 'line',
			data: _data,
			options: {
				animation: {
					duration: 200,
				},
				plugins: {
					legend: {
						display: true,
						position: 'bottom',
						align: 'end',
						labels: {
							usePointStyle: true,
							pointStyle: 'circle',
							boxWidth: 5,
							boxHeight: 5,
							fullSize: true,
						},
					},
				},
				scales: {
					x: {},
					y: {
						beginAtZero: true,
					},
				},
			},
		}

		costOrder.value = lineChart
	}
	const diffOrderTotalGrap = (data: any,startDate: string,endDate: string) => {
		const daysDiff = dayjs(endDate).diff(dayjs(startDate), 'day')
		// ออเดอร์
		const diffMerchantAndOrderLabels = []
		const diffOrderTotals = []
		const defaultGrapZero = []

		if (daysDiff > 0) {
			for (let i = daysDiff; i >= 0; i--) {
				const dateLabel = dayjs(endDate).subtract(i, 'day')
				const calendarLabel = `${dateLabel.format('DD')} ${
					mapThaiMonth[dateLabel.format('MM')].short
				} ${dateLabel.format('YY')}`

				diffMerchantAndOrderLabels.push(calendarLabel)
				const findOrderTotalMatchDate: any = data?.grapInfo?.data?.diffOrderTotal?.find(
					(each: {dashboardAnalyticsDate: string }) => dayjs(each.dashboardAnalyticsDate).format('YYYY-MM-DD') === dateLabel.format('YYYY-MM-DD')
				)
				let diffOrderTotal = 0
				if (findOrderTotalMatchDate) {
					diffOrderTotal += findOrderTotalMatchDate.data
					diffOrderTotals.push(diffOrderTotal)
				} else {
					diffOrderTotals.push(0)
				}
				defaultGrapZero.push(0)
			}
		} 
		else {
			for (let i = 0; i < 24; i++) {
				let diffOrderTotal = 0
				if (data?.grapInfo?.data?.diffOrderTotal?.length > 0) {
					for (const order of data?.grapInfo?.data?.diffOrderTotal) {
						const orderHour = dayjs(order.createdAt).hour()
						if (orderHour === i) {
							diffOrderTotal += order.data
						}
					}
				}
				diffOrderTotals.push(diffOrderTotal)
				defaultGrapZero.push(0)
				diffMerchantAndOrderLabels.push(`${i}:00`)
			}
		}

		const _data = {
			labels: diffMerchantAndOrderLabels,
			datasets: [
				{
					label: 'ส่วนต่างออเดอร์',
					data: diffOrderTotals,
					fill: false,
					borderColor: '#1B3CEB',
					backgroundColor: '#1B3CEB',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
				{
					label: 'ค่าเริ่มต้น',
					data: defaultGrapZero,
					fill: false,
					borderColor: '#FCDC2A',
					backgroundColor: '#FCDC2A',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
			]
		}

		const lineChart = {
			id: 'costlineChart',
			type: 'line',
			data: _data,
			options: {
				animation: {
					duration: 200,
				},
				plugins: {
					legend: {
						display: true,
						position: 'bottom',
						align: 'end',
						labels: {
							usePointStyle: true,
							pointStyle: 'circle',
							boxWidth: 5,
							boxHeight: 5,
							fullSize: true,
						},
					},
				},
				scales: {
					x: {},
					y: {
						beginAtZero: true,
					},
				},
			},
		}

		costOrder.value = lineChart
	}

	const deficitCreditGrap = (data: any,startDate: string,endDate: string) => {
		const daysDiff = dayjs(endDate).diff(dayjs(startDate), 'day')
		// ออเดอร์
		const diffMerchantAndOrderLabels = []
		const deficitCredits = []
		const defaultGrapZero = []

		if (daysDiff > 0) {
			for (let i = daysDiff; i >= 0; i--) {
				const dateLabel = dayjs(endDate).subtract(i, 'day')
				const calendarLabel = `${dateLabel.format('DD')} ${
					mapThaiMonth[dateLabel.format('MM')].short
				} ${dateLabel.format('YY')}`

				diffMerchantAndOrderLabels.push(calendarLabel)
				const findOrderTotalMatchDate: any = data?.grapInfo?.data?.badDebtAmountCredit?.find(
					(each: {dashboardAnalyticsDate: string }) => dayjs(each.dashboardAnalyticsDate).format('YYYY-MM-DD') === dateLabel.format('YYYY-MM-DD')
				)
				let deficitCredit = 0
				if (findOrderTotalMatchDate) {
					deficitCredit += findOrderTotalMatchDate.data.credit
					deficitCredits.push(deficitCredit)
				} else {
					deficitCredits.push(0)
				}
				defaultGrapZero.push(0)
			}
		} 
		else {
			for (let i = 0; i < 24; i++) {
				let deficitCredit = 0
				if (data?.grapInfo?.data?.badDebtAmountCredit?.length > 0) {
					for (const order of data?.grapInfo?.data?.badDebtAmountCredit) {
						const orderHour = dayjs(order.createdAt).hour()
						if (orderHour === i) {
							deficitCredit += order.data.credit
						}
					}
				}
				deficitCredits.push(deficitCredit)
				defaultGrapZero.push(0)
				diffMerchantAndOrderLabels.push(`${i}:00`)
			}
		}

		const _data = {
			labels: diffMerchantAndOrderLabels,
			datasets: [
				{
					label: 'หนี้เสียเครดิตติดลบ',
					data: deficitCredits,
					fill: false,
					borderColor: '#1B3CEB',
					backgroundColor: '#1B3CEB',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
				{
					label: 'ค่าเริ่มต้น',
					data: defaultGrapZero,
					fill: false,
					borderColor: '#FCDC2A',
					backgroundColor: '#FCDC2A',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
			]
		}

		const lineChart = {
			id: 'costlineChart',
			type: 'line',
			data: _data,
			options: {
				animation: {
					duration: 200,
				},
				plugins: {
					legend: {
						display: true,
						position: 'bottom',
						align: 'end',
						labels: {
							usePointStyle: true,
							pointStyle: 'circle',
							boxWidth: 5,
							boxHeight: 5,
							fullSize: true,
						},
					},
				},
				scales: {
					x: {},
					y: {
						beginAtZero: true,
					},
				},
			},
		}

		costOrder.value = lineChart
	}

	const unpaidCheckingGrap = (data: any,startDate: string,endDate: string) => {
		const daysDiff = dayjs(endDate).diff(dayjs(startDate), 'day')
		// ออเดอร์
		const diffMerchantAndOrderLabels = []
		const unpaidCheckings = []
		const defaultGrapZero = []

		if (daysDiff > 0) {
			for (let i = daysDiff; i >= 0; i--) {
				const dateLabel = dayjs(endDate).subtract(i, 'day')
				const calendarLabel = `${dateLabel.format('DD')} ${
					mapThaiMonth[dateLabel.format('MM')].short
				} ${dateLabel.format('YY')}`

				diffMerchantAndOrderLabels.push(calendarLabel)
				const findOrderTotalMatchDate: any = data?.grapInfo?.data?.unpaidBillpayment?.find(
					(each: {dashboardAnalyticsDate: string }) => dayjs(each.dashboardAnalyticsDate).format('YYYY-MM-DD') === dateLabel.format('YYYY-MM-DD')
				)
				let unpaidChecking = 0
				if (findOrderTotalMatchDate) {
					unpaidChecking += findOrderTotalMatchDate.data.badDebtAmountTransferOnly
					unpaidCheckings.push(unpaidChecking)
				} else {
					unpaidCheckings.push(0)
				}
				defaultGrapZero.push(0)
			}
		} 
		else {
			for (let i = 0; i < 24; i++) {
				let unpaidChecking = 0
				if (data?.grapInfo?.data?.unpaidBillpayment?.length > 0) {
					for (const order of data?.grapInfo?.data?.unpaidBillpayment) {
						const orderHour = dayjs(order.createdAt).hour()
						if (orderHour === i) {
							unpaidChecking += order.data.badDebtAmountTransferOnly
						}
					}
				}
				unpaidCheckings.push(unpaidChecking)
				defaultGrapZero.push(0)
				diffMerchantAndOrderLabels.push(`${i}:00`)
			}
		}

		const _data = {
			labels: diffMerchantAndOrderLabels,
			datasets: [
				{
					label: 'หนี้เสียค้างชำระบิล',
					data: unpaidCheckings,
					fill: false,
					borderColor: '#1B3CEB',
					backgroundColor: '#1B3CEB',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
				{
					label: 'ค่าเริ่มต้น',
					data: defaultGrapZero,
					fill: false,
					borderColor: '#FCDC2A',
					backgroundColor: '#FCDC2A',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 8,
					tension: 0.4,
				},
			]
		}

		const lineChart = {
			id: 'costlineChart',
			type: 'line',
			data: _data,
			options: {
				animation: {
					duration: 200,
				},
				plugins: {
					legend: {
						display: true,
						position: 'bottom',
						align: 'end',
						labels: {
							usePointStyle: true,
							pointStyle: 'circle',
							boxWidth: 5,
							boxHeight: 5,
							fullSize: true,
						},
					},
				},
				scales: {
					x: {},
					y: {
						beginAtZero: true,
					},
				},
			},
		}

		costOrder.value = lineChart
	}

	watch(startDate, async () => {
		if (!isFromBtn.value) {
			shopStore.updateDateSelect(DATE_SELECT.DATE_PICK)
			selectDate(shopStore.dateSelect)
		}
		await fetchDashboard()
	})
	onMounted(async () => {
		selectDate(shopStore.dateSelect)
		await fetchDashboard()
	})

	return {
		fetchDashboard,
		isLoadingUser,
		isLoadingOrder,
		dashboardBackOffice,
		selectDate,
		textHeader,
		state,
		exportFailOrderReport,
		exportErrorCreditReport,
		currentTab,
		activeGrap,
		selectGrap,
		dashboardData,
		startDate,
		endDate,
		fetchCostlist,
		costOrder,
		exportFailRejectOrderReport,
		onSetDatePickup,
		exportErrorCreditMerchantFee,
	}
}