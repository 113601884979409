import { reactive, ref } from 'vue'
import { CLAIM_TYPE, CLAIM_IMAGE_CATEGORY, CLAIM_STATUS } from './type'
import { getClaimListAPI, updateClaimStatus } from '@/api/claim/claim.api'
import { ElMessageBox, ElNotification } from 'element-plus'
import { useCourierStore } from '@/pinia/courier/courier.pinia'
import { SHOP_CREDIT_TYPE } from '@/type/shop'

type BankInfo = {
	accountName: string
	accountNumber: string
	bankName: string
	bankImage: string
}

const constantClaimTypeOption = [
	{
		label: 'พัสดุเสียหาย',
		value: CLAIM_TYPE.DAMAGED_BOX
	},
	{
		label: 'สินค้าภายในได้รับความเสียหาย',
		value: CLAIM_TYPE.DAMAGED_PRODUCT
	},
	{
		label: 'พัสดุสูญหาย',
		value: CLAIM_TYPE.MISSING_PRODUCT
	},
	{
		label: 'ได้รับสินค้าผิด',
		value: CLAIM_TYPE.WRONG_PRODUCT
	},
]

const constantClaimStatus = [
	{
		label: 'เคลมสำเร็จ',
		value: CLAIM_STATUS.APPROVED
	},
	{
		label: 'ระหว่างตรวจสอบเอกสาร',
		value: CLAIM_STATUS.PENDING
	},
	{
		label: 'ระหว่างดำเนินการ',
		value: CLAIM_STATUS.IN_PROGRESS
	},
	{
		label: 'ยื่นเอกสารเพิ่มเติม',
		value: CLAIM_STATUS.ADDITIONAL
	},
	{
		label: 'ถูกปฏิเสธ',
		value: CLAIM_STATUS.REJECTED
	}
]

export const useClaim = () => {
	const searchQuery = reactive({
		limit: 10,
		offset: 1,
		totals: 0,
		type: null,
		status: null,
		search: null as any,
		courier: null,
	})
	const isFetching = ref(false)
	const claimList = ref<any[]>([])
	const claimStatusSelect = ref<null | CLAIM_STATUS>(null)
	const updateClaimStatusSelected = ref<CLAIM_STATUS>(CLAIM_STATUS.APPROVED)
	const selectedUpdateOrderId = ref<string>()
	const bankInfo = ref<BankInfo>()
	const shopType = ref<string>()
	const isShowDialogUpdateStatus = ref(false)
	const rejectReason = ref('')
	const courierStore = useCourierStore()
	const totalClaim = ref('')
	const statusClaim = ref('')
	const isConfirmToClaimSLA = ref<boolean>(false)

	const getClaimList = async (onTime?: string) => {
		//
		isFetching.value = true
		try {
			const payload: any = {
				type: searchQuery.type,
				offset: searchQuery.offset,
				limit: searchQuery.limit,
				trackingNumber: searchQuery.search,
			}
		
			if (searchQuery.courier !== 'all') {
				payload.courier = searchQuery.courier
			}
			
			if (searchQuery.status !== 'all') {
				payload.status = searchQuery.status
			}

			if(onTime === 'ON_TIME_DELIVERY'){
				claimList.value = claimList.value.filter((claim: { type: string }) => claim.type === 'ON_TIME_DELIVERY')
				payload.type = 'ON_TIME_DELIVERY'
			}

			const { data: response } = await getClaimListAPI(payload)
			
			claimList.value = courierStore.mapCourierWithOrder({ data: response.data }).data

			searchQuery.totals = response.pagination?.totals
		} catch (error) {
			//
		} finally {
			isFetching.value = false
		}
	}

	const onUpdateClaim = (row: any) => {
		isConfirmToClaimSLA.value = false
		shopType.value = row.shopId.creditType
		if(row.shopId.creditType != SHOP_CREDIT_TYPE.TOP_UP){
			bankInfo.value = {
				accountName: row.shopId.kyc.bank.accountName,
				accountNumber: row.shopId.kyc.bank.accountNumber,
				bankImage: row.shopId.kyc.bank.accountIssuer? row.shopId.kyc.bank?.accountIssuer.image : null,
				bankName: row.shopId.kyc.bank.accountIssuer? row.shopId.kyc.bank?.accountIssuer.name : null
			}
		}
		
		//
		selectedUpdateOrderId.value = row.orderId
		rejectReason.value = row.rejectedReason
		updateClaimStatusSelected.value = row.status
		isShowDialogUpdateStatus.value = true
		statusClaim.value = row.status
		totalClaim.value = row.type !== 'ON_TIME_DELIVERY'?(row.totalClaim && row.totalClaim > 0 ?  row.totalClaim || 0 : row.cost)
			: (row.totalClaim && row.totalClaim > 0 ?  row.totalClaim || 0 : row.merchantShipmentFee || 0)
	}

	const onConfirmClaim = async (onTime?: string) => {
		//
		try {
			const payload: any = {
				orderId: selectedUpdateOrderId.value as string,
				status: updateClaimStatusSelected.value,
				rejectedReason: rejectReason.value,
				type: onTime,
				isConfirmToClaimSLA: isConfirmToClaimSLA.value,
				statusClaim: statusClaim.value
			}
			if(updateClaimStatusSelected.value == CLAIM_STATUS.APPROVED){
				payload.totalClaim = parseFloat(totalClaim.value)
			}

			await updateClaimStatus(payload)

			ElNotification({
				title: 'สำเร็จ',
				message: 'อัพเดทสถานะสำเร็จ',
				type: 'success'
			})

			getClaimList(onTime)

			isShowDialogUpdateStatus.value = false
		} catch (error) {
			if(onTime) {
				await ElMessageBox.confirm(`ยืนยันการคืนเครดิตให้ลูกค้า ${totalClaim.value} บาท`, {
					confirmButtonText: 'ยืนยัน',
					cancelButtonText: 'ยกเลิก',
					type: 'warning',
				  })
				isConfirmToClaimSLA.value = true
				onConfirmClaim('ON_TIME_DELIVERY')
			}
		}
	}

	return {
		isFetching,
		claimList,
		constantClaimStatus,
		constantClaimTypeOption,
		searchQuery,
		getClaimList,
		claimStatusSelect,
		onUpdateClaim,
		isShowDialogUpdateStatus,
		rejectReason,
		onConfirmClaim,
		updateClaimStatusSelected,
		totalClaim,
		bankInfo,
		shopType
	}
}